<template>
   

    <div v-if="usageAllowed">
        <router-view v-slot="{ Component }">
            <keep-alive><component :is="Component" /></keep-alive>
        </router-view>
       
        <Economy v-show="$route.path == '/economy'"/>
    </div>
    <UsageLimitModal ref='usageLimitModal' :accessCheck="accessCheck"/>
</template>

<script>

import BrowserUtils from '../utilities/BrowserUtils';
import EventBus from '../event-bus';

import Economy from '../components/home/Economy';

import UsageLimitModal from '../components/modal/UsageLimitModal.vue';

import UserService from '../service/UserService';
// import Lists from './Lists.vue';
// import Dropdown from 'primevue/dropdown';

export default {
    name: 'Home',
    data() {
        return {
            justMounted: false,
            active: false,
            accessCheck: null,
            usageAllowed: false,
           
        };
    },
    components: {
        // Lists,
        Economy,
        UsageLimitModal,
        // Dropdown,
    },
    computed: {

        selectedFeedParam() {
            return this.selectedFeedType.feedType;
        },

    },

    mounted() {
        this.justMounted = true;
        console.debug('mounting Home');
    },
    activated() {
        this.active = true;
        console.debug('activated Home');
        this.justMounted = false;
        if( this.isMobile() ) {
            UserService.checkAnalysisToolsUsageRate("tools").then( resp => {
                if( resp.data.status == 'error') {
                    this.accessCheck = resp.data.accessCheck;
                    this.$refs.usageLimitModal.open();
                    
                }
                else {
                    this.usageAllowed = true;
                    this.accessCheck = null;
                }
            });
        }
    },
    deactivated() {
        console.debug('deactivated Home');
        this.active = false;
        this.usageAllowed = false;
        this.accessCheck = null;
    },
    unmounted() {
        console.debug('unmounted Home');
    },

    methods: {
        refresh() {
            EventBus.emit('refresh-feed');
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },
    },
};
</script>

<style scoped>
/* .tab-div {
    padding: 16px 0px;
} */
.refresh-btn {
    border-radius: 50px;
    color: #32364e;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
}
.refresh-btn:hover {
    color: white;
}
::v-deep(.feed-type-dropdown .p-inputtext) {
    font-size: 14px !important;
    font-weight: 400 !important;
    display: flex !important;
    color: #32364e;
}
::v-deep(.feed-type-dropdown .pi-chevron-down) {
    color: #32364e;
}
::v-deep(.tab-content) {
    padding-top: 0px;
}
.feed-type-dropdown {
    padding: 0px 8px;
    border-radius: 50px;
    width: 138px;
    border-color: #33cc99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
}

/* @media (max-width: 760px) {
    .pill-menu-bar.main-header {
      padding: 5px;
    }
    .tab-content {
      padding-top: 0px;
    }
    ::v-deep(.navigation button.p-button) {
      margin: 5px !important;
    }
    ::v-deep(.optional-buttons-container) {
      margin-right: 5px !important;
    }
} */
@media (max-width: 760px) {
.tab-div {
    padding: 0px;
}
}
</style>